import * as types from '../constants/actions/loan-account';
import { showMessageBar, showLoading, hideLoading } from '../actions/app';
import {
  link as requestLinkLoanAccount,
  notifyLender,
  all as requestFetchLoanAccounts,
  requestStatementListAction,
  requestScheduledDocumentListAction,
  requestDeleteLoanAccount
} from '../api/loan-account';
import LoanAccountService from '../services/loan-account';
import { findById } from '../helpers/app';
import { dateWithFormatOrNa } from '../helpers/formatters';

const loanAccountService = new LoanAccountService();

/**
 * Link a loan account.
 * 
 * @param payload
 */
export function linkLoanAccount(payload) {
  return function (dispatch) {
    let newData = {
      ...payload,
      dob: payload.dob ? dateWithFormatOrNa(payload.dob) : '',
      ssn: payload.ssn ? payload.ssn : ''
    };

    return requestLinkLoanAccount(newData)
      .then(function (response) {
        let data = response.data;

        if (data.status === 1 && data.statusCode === 200) {
          dispatch(showMessageBar({ status: 'success', text: data.message }));
        } else {
          dispatch(showMessageBar({ status: 'error', text: data.message }));
        }

        return response;
      })
      .catch((error) => {
        return Promise.reject(error);
      })
  }
}

/**
 * Notify to lender than linking loan account has failed.
 * 
 * @param {*} loanAccount 
 */
export function notifyLoanAccount(loanAccount) {
  return {
    type: types.NOTIFY_LOAN_ACCOUNT_LINKING,
    payload: notifyLender(loanAccount)
  }
}

/**
 * Notify to lender than linking loan account has failed.
 * 
 * @param {*} loanAccount 
 */
export function statementListAction(loanId) {
  return function (dispatch) {
    dispatch(loadingStatementList())
    return requestStatementListAction(loanId)
      .then(function (response) {
        let data = response.data;
        if (data.status === 1 && data.statusCode === 200) {
          let statements = data.result;
          dispatch(setStatementList(statements));
        } else if (data.status === 0 && data.statusCode === 201) {
          dispatch(setStatementList([]));
        }

        return response;
      })
      .catch((error) => {
        return Promise.reject(error);
      })
  }
}

export function loadingStatementList() {
  return {
    type: types.LOADING_STATEMENT_LIST
  }
}


export function setStatementList(payload) {
  return {
    type: types.SET_STATEMENT_LIST,
    payload
  }
}


/**
 * Fetch all loan accounts.
 */
export function fetchLoanAccounts() {
  return function (dispatch) {
    return requestFetchLoanAccounts()
      .then(function (response) {
        let data = response.data;

        if (data.status === 1 && data.statusCode === 200) {
          let loanAccounts = data.result.loans;

          if (loanAccounts.length > 0) {
            let selectedLoanAccount = parseInt(loanAccountService.getSelectedId(), 10);
            selectedLoanAccount = findById(selectedLoanAccount, loanAccounts);

            // Select the first loan account if none is selected yet or the selected was removed.
            if (!selectedLoanAccount) {
              selectedLoanAccount = loanAccounts[0];
              loanAccountService.setSelectedLoanAccount(selectedLoanAccount.id);
            }
            if (!JSON.parse(sessionStorage.getItem("acceptedBkUdfPopup"))) {
              dispatch(setShowBkUdfPopup(data.result.showBkUdfPopup));
            }
            dispatch(setLoanAccounts(loanAccounts));
            dispatch(setSelectedLoanAccount(selectedLoanAccount.id));
          }
        } else if (data.status === 1 && data.statusCode === 201) {
          dispatch(setLoanAccounts([]));
        }

        return response;
      })
      .catch((error) => {
        return Promise.reject(error);
      })
  }
}
export function setShowBkUdfPopup(payload) {
  return {
    type: types.SET_SHOW_BK_UDF_POPUP,
    payload,
  };
}
export function setLoanAccounts(payload) {
  return {
    type: types.SET_LOAN_ACCOUNTS,
    payload
  }
}

export function setSelectedLoanAccount(payload) {
  loanAccountService.setSelectedLoanAccount(payload);

  return {
    type: types.SET_SELECTED_LOAN_ACCOUNT,
    payload
  }
}

export function deleteLoanAccount(id) {
  return function (dispatch) {
    dispatch(showLoading());

    return requestDeleteLoanAccount(id)
      .then(function (response) {
        let data = response.data;
        dispatch(hideLoading());

        if (data.status === 1 && data.statusCode === 200) {
          dispatch(showMessageBar({
            status: 'success',
            text: data.message
          }));
        }

        return response;
      })
      .catch(() => {
        dispatch(hideLoading());
      });
  }
}

/**
 * Notify to lender than linking loan account has failed.
 * 
 * @param {*} loanAccount 
 */
export function scheduledDocumentListAction(loanId) {
  return function (dispatch) {
    dispatch(loadingScheduledDocumentList())
    return requestScheduledDocumentListAction(loanId)
      .then(function (response) {
        const data = response.data;
        if (data.status === 1 && data.statusCode === 200) {
          const documents = data.result;
          dispatch(setScheduledDocumentList(documents));
        } else if (data.status === 0 && data.statusCode === 201) {
          dispatch(setScheduledDocumentList([]));
        }

        return response;
      })
      .catch((error) => {
        return Promise.reject(error);
      })
  }
}

export function loadingScheduledDocumentList() {
  return {
    type: types.LOADING_SCHEDULED_DOCUMENT_LIST
  }
}


export function setScheduledDocumentList(payload) {
  return {
    type: types.SET_SCHEDULED_DOCUMENT_LIST,
    payload
  }
}
