import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { destroy } from 'redux-form';
import LoanAccountService from '../../../services/loan-account';
import { uploadImage } from '../../../api/loan-account';
import { findById } from '../../../helpers/app';

import { 
  linkLoanAccount, notifyLoanAccount, setSelectedLoanAccount, deleteLoanAccount
} from '../../../actions/loan-account';
import { showLoading, hideLoading, showMessageBar } from '../../../actions/app';

// Import components
import DeleteScheduledLoanConfirmation from '../../../components/delete-scheduled-loan-confirmation';
import DeleteLoanAccountConfirmation from '../../../components/delete-loan-account-confirmation';
import LinkingLoanAccountError from '../../../components/linking-loan-account-error';
import LinkingLoanAccountNotice from '../../../components/linking-loan-account-notice';
import LoanAccount from '../../../containers/loan-account';
import LoanAccountCollateral from '../../../components/application/loan-account/collateral';
import LoanAccountHeader from '../../../components/application/loan-account/header';
import LoanAccountLinkForm from '../../../components/loan-account-link-form';
import LoanAccountProfile from '../../../components/application/loan-account/profile';
import Modal from '../../../components/application/modal';
import InfoModal from '../../../components/application/modals/info-modal';
import { dateWithFormatOrNa } from '../../../helpers/formatters';
import UploadPhoto from '../../upload-photo';

const VEHICLE_IMAGE = 1;
const USER_IMAGE = 2;

class Index extends Component {
  state = {
    isModalOpen: false,
    modal: null
  }

  uploadType = null;

  componentDidMount() {
    if (this.props.loanAccounts.length === 0) {
      this.setState({
        isModalOpen: true,
        modal: 'LINK_LOAN_ACCOUNT'
      });
    }
  }

  closeModal = () => {
    this.setState({
      isModalOpen: false,
      modal: null
    });
  }

  /**
   * Display or hidden the delete loan account modal confirmation.
   */
  displayDeleteLoanAccountModal = () => {
    this.setState({
      isModalOpen: true,
      modal: 'DELETE_LOAN_ACCOUNT'
    });
  }

  /**
   * Display or hidden the delete scheduled loan account modal confirmation.
   */
   displayDeleteScheduledLoanAccountModal = () => {
    this.setState({
      isModalOpen: true,
      modal: 'DELETE_SCHEDULED_LOAN_ACCOUNT'
    });
  }

  /**
   * Open or hidden the link loan account modal.
   */
  displayLinkLoanAccountModal = (empty = true) => {
    if (empty) {
      this.props.destroy('loanAccountLinkForm');
    }

    this.setState({
      isModalOpen: true,
      modal: 'LINK_LOAN_ACCOUNT'
    });
  }

  /**
   * Open or hidden the upload image modal.
   */
  displayUploadImage = () => {
    this.setState({
      isModalOpen: true,
      modal: 'UPLOAD_IMAGE'
    });
  }

  /**
   * Handle the event when user change the selected loan account.
   */
  handleChangeLoanAccount = (value) => {
    this.props.setSelectedLoanAccount(value);
  }

  /**
   * Delete the selected loan account.
   */
  handleDeleteLoanAccount = () => {
    this.closeModal();
    if (this.props.selectedLoanAccount.isSchedule) {
        // Open scheduled loan confirmation modal
        this.displayDeleteScheduledLoanAccountModal();
    } else {
        this.props.deleteLoanAccount(this.props.selectedLoanAccount.id)
        .then((response) => {
            let data = response.data;
            
            if (data.status === 1 && data.statusCode === 200) {
              this.props.onDeleteLoanAccountSuccess();
            }
        });
    }
  }

  /**
   * Delete the selected scheduled loan account.
   */
   handleDeleteScheduledLoanAccount = () => {
    this.closeModal();
    this.props.deleteLoanAccount(this.props.selectedLoanAccount.id)
        .then((response) => {
        let data = response.data;

        if (data.status === 1 && data.statusCode === 200) {
          this.props.onDeleteLoanAccountSuccess();
        }
    });
  }

   /**
   * Link a loan account.
   * 
   * @values The form values.
   */
  linkLoanAccount = values => {
    this.closeModal();
    this.props.showLoading();

    return this.props.linkLoanAccount(values)
      .then((response) => {
        this.props.hideLoading();
        let data = response.data;

        if (data.status === 0 && data.statusCode === 203) {
          this.setState({
            isModalOpen: true,
            modal: 'LINK_LOAN_ACCOUNT_ERROR'
          });
        } else if (data.status === 1 && data.statusCode === 200) {
          this.props.onLinkLoanAccountSuccess(data.result.loanDetails.id);
          this.props.onLoanInfoSuccess();
          
          if (this.props.autoEnrollStatementsWhenLinking === '1') {
            this.setState({
              isModalOpen: true,
              modal: 'LINK_LOAN_ACCOUNT_SUCCESS_AUTO_ENROLL',
              content: data.message
            });
          }
        }
      })
      .catch(() => {
        this.props.hideLoading();
      });
  };

  /**
   * Return to the link account form to try again the proccess.
   *
   * @param event
   */
  handleTryAgainLinkAccount = (event) => {
    this.setState({
      isModalOpen: false,
      modal: null
    }, () => {
      this.displayLinkLoanAccountModal(false);
    });
  }

  /**
   * Request that the loan account link be sent as notification to the lender.
   *
   * @param event
   */
  handleNotifyLender = () => {
    this.closeModal();
    this.props.loanAccountLinkForm.dob = this.props.loanAccountLinkForm.dob ? dateWithFormatOrNa(this.props.loanAccountLinkForm.dob) : null;
    this.props.notifyLoanAccount(this.props.loanAccountLinkForm)
    
      .then((response) => {
        let data = response.value.data; 

        if (data.status === 1 && data.statusCode === 200) {
          this.setState({
            isModalOpen: true,
            modal: 'LINK_LOAN_ACCOUNT_SUCCESS'
          });
        }
      });
  }

  /**
   * Dismiss the modal and clear the loan account form data.
   *
   * @param event
   */
  handleBackToHome = () => {
    this.props.destroy('loanAccountLinkForm');
    this.closeModal();
  }

  /**
   * Display the modal for upload the vehicle image. 
   */
  handleUploadVehicleImage = () => {
    this.uploadType = VEHICLE_IMAGE;
    this.displayUploadImage();
  }

  /**
   * Display the modal for upload the user image. 
   */
  handleUploadUserImage = () => {
    this.uploadType = USER_IMAGE;
    this.displayUploadImage();
  }

  /**
   * Upload the user or the vehicle image.
   */
  uploadImage = (image) => {
    this.closeModal();
    this.props.showLoading();

    let data = {
      loanId: this.props.selectedLoanAccount.id,
      uploadType: this.uploadType,
      image
    }

    uploadImage(data)
      .then((response) => {
        this.props.hideLoading();
        let dataResponse = response.data;

        if (dataResponse.status === 1 && dataResponse.statusCode === 200) {
          this.props.showMessageBar({
            status: 'success',
            text: dataResponse.message
          });

          this.props.onLoanInfoSuccess();
        }
      })
      .catch(function() {
        this.props.hideLoading();
      });
  }

  /**
   * Render the loan account header.
   */
  renderLoanAccountHeader() {
    let { loanAccounts, selectedLoanAccount } = this.props;

    if (loanAccounts === null || selectedLoanAccount === null) {
      return null;
    }

    return (
      <LoanAccountHeader
        whiteLabel={this.props.whiteLabel}
        loanAccounts={loanAccounts}
        loanNo={selectedLoanAccount.loanNo}
        onLinkLoanAccount={this.displayLinkLoanAccountModal}
        onChangeLoanAccount={this.handleChangeLoanAccount} />
    )
  }

  /**
   * Render the loan account collateral.
   */
  renderLoanAccountCollateral() {
    let { selectedLoanAccount } = this.props;

    if (selectedLoanAccount === null) {
      return null;
    }

    return (
      <LoanAccountCollateral 
        year={selectedLoanAccount.loanCollateral.collatYear}
        make={selectedLoanAccount.loanCollateral.collatMake}
        model={selectedLoanAccount.loanCollateral.collatModel}
        onDelete={this.displayDeleteLoanAccountModal}
        withOptions={!this.props.isSchedule}
        whiteLabel={this.props.whiteLabel}
        displayScheduledStatusTileAtTopLeft={this.props.displayScheduledStatusTileAtTopLeft}
        selectedLoanAccount={selectedLoanAccount}
        hidePaidOff={this.props.client.hidePaidOff}
        renderLoanStatusOrSchedule={this.renderLoanStatusOrSchedule} />
    );
  }

  /**
   * Render the profile section on loan account component.
   */
  renderLoanAccountProfile() {
    let { selectedLoanAccount } = this.props;

    if (selectedLoanAccount === null) {
      return null;
    }

    return <LoanAccountProfile
      color={this.props.colorCode}
      userImage={selectedLoanAccount.borrower.userImage}
      vehicleImage={selectedLoanAccount.vechileImage 
        ? selectedLoanAccount.vechileImage 
        : this.props.collateralImage}
      onUploadUserImage={this.handleUploadUserImage}
      onUploadVehicleImage={this.handleUploadVehicleImage}
      whiteLabel={this.props.whiteLabel} />
  }

  renderModalContent() {
    switch (this.state.modal) {
      case 'LINK_LOAN_ACCOUNT' :
        return <LoanAccountLinkForm 
          isSsn={this.props.client.isSsn}
          field1Type={this.props.client.linkField1Type}
          field2Type={this.props.client.linkField2Type}
          field3Type={this.props.client.linkField3Type}
          loanMessage={this.props.whiteLabel.labels['Account Min Length Msg']}
          whiteLabel={this.props.whiteLabel}
          onSubmit={this.linkLoanAccount} />;
      case 'LINK_LOAN_ACCOUNT_ERROR' :
        return <LinkingLoanAccountError
          onTryAgain={this.handleTryAgainLinkAccount}
          onNotifyLender={this.handleNotifyLender} 
          whiteLabel={this.props.whiteLabel}/>
      case 'LINK_LOAN_ACCOUNT_SUCCESS' :
        return <LinkingLoanAccountNotice 
          onBackToHome={this.handleBackToHome}
          whiteLabel={this.props.whiteLabel} />
      case 'LINK_LOAN_ACCOUNT_SUCCESS_AUTO_ENROLL' :
        return <InfoModal
          type="success"
          content={this.state.content}
          noButton={true}
          whiteLabel={this.props.whiteLabel}/>
      case 'UPLOAD_IMAGE' :
        return <UploadPhoto 
          color={this.props.colorCode}
          aspectRatio={(this.uploadType === VEHICLE_IMAGE) ? 2 : 1}
          onUpload={this.uploadImage} 
          onCancel={this.closeModal}
          whiteLabel={this.props.whiteLabel} />
      case 'DELETE_LOAN_ACCOUNT' :
        return <DeleteLoanAccountConfirmation
          whiteLabel={this.props.whiteLabel}
          onCancel={this.closeModal}
          onDelete={this.handleDeleteLoanAccount} />
    case 'DELETE_SCHEDULED_LOAN_ACCOUNT' :
        return <DeleteScheduledLoanConfirmation
          whiteLabel={this.props.whiteLabel}
          onCancel={this.closeModal}
          onDelete={this.handleDeleteScheduledLoanAccount} />
      default :
        return null;
    }
  }

  renderModal() {
    return (
      <Modal 
        isOpen={this.state.isModalOpen}
        onClose={this.closeModal}>
        { this.renderModalContent() }
      </Modal>
    );
  }

  renderLoanStatusOrSchedule(selectedLoanAccount, params) {
    if (selectedLoanAccount === undefined || params.displayTile === false) {
      return '';
    }
    
    if (selectedLoanAccount.loanStatusText !== '' && params.hidePaidOff === false) {
      let styleTile = "stamps-red loan-account__impress";
      if (params.displayTile) {
        styleTile = "stamps-red loan-account__impress-left margin-tile";
      }
      return (<div className={styleTile}> {selectedLoanAccount.loanStatusText} </div>);
    } else if (selectedLoanAccount.hasScheduled) {
      let styleTile = "text-center loan__payment-status";
      if (params.displayTile) {
        styleTile = " align-left loan__payment-status-left";
      }
      return (<div className={styleTile}><span className="nowrap-text loan__payment-status">
            {params.scheduledWhiteLabel}</span></div>);
    } else {
      return '';
    }
  }

  /**
   * Render the component view.
   */
  render() {
    let { loanAccounts } = this.props;

    /**
     * The component is not rendered until we have the accounts 
     * and have one selected.
     */
    if (loanAccounts === null) {
      return null;
    }

    return (
      <div>
        {loanAccounts.length > 0 
          ? (
            <LoanAccount
              header={this.renderLoanAccountHeader()}
              collateral={this.renderLoanAccountCollateral()}
              profile={this.renderLoanAccountProfile()}
              selectedLoanAccount={this.props.selectedLoanAccount}
              whiteLabel={this.props.whiteLabel}
              hideLastNextPmtInfo={this.props.client.hideLastNextPmtInfo}
              hidePaidOff={this.props.client.hidePaidOff}
              displayScheduledStatusTileAtTopLeft={this.props.displayScheduledStatusTileAtTopLeft}
              renderLoanStatusOrSchedule={this.renderLoanStatusOrSchedule}
              >
              {this.props.form}
            </LoanAccount>
          ) : (
            <div className="loan-account-placeholder" onClick={this.displayLinkLoanAccountModal}>
              <i className="fas fa-plus-circle loan-account-placeholder__icon-add" />
              <p className="loan-account-placeholder__text">
                 
                 {this.props.whiteLabel.labels['Add your Loan Account']}
              </p>
            </div>
          )
        }

        { this.renderModal() }
      </div>
    );
  }
}

Index.defaultProps = {
  isSchedule: false
}

const loanAccountService = new LoanAccountService();

const mapStateToProps = (state) => {
  let { client, app, whiteLabel } = state;
  let paymentMethod =  findById(app.payment.selectedMethod, client.cards);

  let displayScheduledStatusTileAtTopLeft = false;
  if (state.client.data.displayScheduledStatusTileAtTopLeft === '1') {
    displayScheduledStatusTileAtTopLeft = true;
  }

  let props = {
    client: state.client.data,
    colorCode: client.data.colorCode,
    loanAccounts: app.loanAccounts,
    selectedLoanAccount: loanAccountService.get(app.selectedLoanAccount),
    collateralImage: client.clientImages ? client.clientImages.secondaryImageName : null,
    paymentMethod,
    whiteLabel:whiteLabel,
    displayScheduledStatusTileAtTopLeft: displayScheduledStatusTileAtTopLeft,
    autoEnrollStatementsWhenLinking: (client.data.autoEnrollStatementsWhenLinking) ? client.data.autoEnrollStatementsWhenLinking : '0'
  }

  // Pass the link loan account form data if exists.
  let loanAccountLinkReduxForm = state.form.loanAccountLinkForm;

  if (loanAccountLinkReduxForm && loanAccountLinkReduxForm.values) {
    props.loanAccountLinkForm = loanAccountLinkReduxForm.values
  }

  return props;
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      showLoading, hideLoading, showMessageBar, linkLoanAccount, deleteLoanAccount, notifyLoanAccount, 
      setSelectedLoanAccount, destroy
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Index);
